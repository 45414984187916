.simple-modal
  display: none
  position: fixed
  z-index: 20
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: auto
  background-color: rgba(0, 0, 0, 0.8)

  &--future-content
    display: none

  &--content
    background-color: $c_white
    margin: 15% auto
    padding: 20px
    width: 80%

    @include second-breakpoint
      padding: 10px

  &--close
    position: absolute
    right: 2%
    top: 5%

    &:hover
      cursor: pointer

.catalog-detail__show-yt-video
  position: absolute
  z-index: 2
  text-align: center
  margin-left: 20px
  margin-top: 20px
  span
    display: block
    text-transform: uppercase
    font-size: 9px
    line-height: normal
  svg
    width: 40px
    height: auto
    color: #0cb02b
  &:hover
    cursor: pointer
    svg
      color: $vivaYellow

.catalog-detail-modal-yt

  .simple-modal--content
    height: 80%
    margin: 5% auto
    @include second-breakpoint
      width: 90% !important

    iframe
      width: 100%
      height: 100%