//-- base import
@import 'front/_variables'
@import 'front/responsive-mixins'
@import 'front/device-mixins'
@import 'addons/css-reset'
@import 'addons/clearfix'
@import 'front/content/content'
@import 'front/modal-window'
@import 'front/float-mobile-contact'
@import 'front/simplemodal'

//-- base styles, classes
.content-width
  width: $baseWidth // nekde v poznamkach je domluvena nova sirka 1400px
  margin: 0 auto
  @include first-breakpoint
    width: 960px
  @include second-breakpoint
    width: 720px
  @include third-breakpoint
    width: 100%
    padding: 0 $paddingBase*1.5

.content-block
  @include baseContentStyle()

.double-column
  display: flex
  padding-bottom: $paddingBase*3
  .double-column__sidebar
    width: 25%
    padding-right: $paddingBase*1.5
    order: 1
  .double-column__content
    width: 75%
    order: 2
    padding-left: $paddingBase*1.5
    @include baseContentStyle()
  @include second-breakpoint
    flex-wrap: wrap
    .double-column__sidebar,
    .double-column__content
      width: 100%
    .double-column__sidebar
      order: 2
    .double-column__content
      order: 1
      padding-bottom: $paddingBase*3

html, body
  font-family: $fontFamilyBase
  font-size: $fontSizeBase
  line-height: $lineHeightBase

body.scroll-fix
  padding-top: 180px

a
  color: $vivaGreenDark
  transition: 300ms
  &:hover
    color: $vivaYellow

h1
  font-size: 32px
  line-height: 38px
  .headline-icon
    vertical-align: middle
    height: 65px !important
    margin-right: $paddingBase
    position: relative
    top: -5px

h2
  font-size: 30px
  line-height: 36px

h3
  font-size: 26px
  line-height: 31px

p
  font-size: $fontSizeBase
  line-height: $lineHeightBase

.t-center
  text-align: center

.t-left
  text-align: center

.t-right
  text-align: center

.dn
  display: none

.clearfix
  @include addClearFix()

//-- imports
@import './www/external/baguetteBox/css/baguetteBox'
@import './www/external/splide/css/splite'
@import './www/external/nouislider/css/nouislider'
@import 'front/fontawesome-init'
@import 'front/fonts'
@import 'front/mixins'
@import 'front/carousel'
@import 'front/breadcrumb'
@import 'front/menu'
@import 'front/gallery'
@import 'front/form'
@import 'front/error-page'
@import 'front/header/header'
@import 'front/header/search-form'
@import 'front/content/homepage'
@import 'front/content/banners-square'
@import 'front/content/article-list-of-annotation'
@import 'front/content/list-of-text-pages'
@import 'front/content/banners-sidebar'
@import 'front/content/menu-sidebar'
@import 'front/content/catalog-list'
@import 'front/content/catalog-list-table'
@import 'front/content/catalog-detail'
@import 'front/content/person-info'
@import 'front/content/product-list-in-content'
@import 'front/content/article-detail'
@import 'front/content/people-list'
@import 'front/content/loading'
@import 'front/content/form-builder'
@import 'front/content/form-builder-ajax-form'
@import 'front/content/form-builder-full-view'
@import 'front/content/footer'
@import 'front/content/wysiwyg-style'
@import 'front/content/list-of-children-annotation'
@import 'front/content/product-list'
@import 'front/content/mobile-call-us'
@import 'front/content/two-columns'
@import 'front/content/right-fixed-buttons'
@import 'front/content/button-go-up'
@import 'front/footer/copyright'
@import 'front/footer/dark-container'
@import 'front/custom/link-for-download'
@import 'front/cookie-bar'
@import 'front/packing-paragraphs'
@import 'front/language-flag'
@import 'front/clock'
@import 'front/forclift-compare'

.default-button-style-green
  @include buttonDefault()
  &:hover
    color: $c_white

.default-button-style
  @include buttonDefaultForButtonEl()

.headline-style
  font-size: 32px
  line-height: 38px
  color: $vivaGreenDark