.float-mobile-contact
  display: none
  @include second-breakpoint()
    display: block
  position: fixed
  z-index: 3
  bottom: 0
  width: 100%

  &--header-rounded
    &.open,
    &.init-open
      svg
        transform: rotate(180deg)
    position: relative
    overflow: hidden
    height: 35px
    text-align: center
    svg
      transition: transform 200ms linear
      top: 10px
      position: relative
      fill: $c_white
      width: 20px
      height: 20px
    &:before
      border-radius: 100%
      position: absolute
      background: $vivaGreenDark
      right: -200px
      left: -200px
      bottom: -200px
      top: 0
      content: ''

  &--header
    font-size: 16px
    text-align: center
    color: $c_white
    padding-bottom: $paddingBase

  &--header,
  &--links
    background: $vivaGreenDark

  &--links
    display: none
    &.open,
    &.init-open
      display: flex
      flex-wrap: wrap
    justify-content: center
    padding: $paddingBase 0 $paddingBase*1.5 0
    &--headline
      width: 100%
      text-align: center
      color: $c_white
      padding-bottom: $paddingBase
    > a
      margin: 0 $paddingBase*0.5

  $iconSizeW: 40px
  $iconSizeH: 40px

  &--email,
  &--wa
    display: flex
    justify-content: center
    align-items: center
    width: $iconSizeH
    height: $iconSizeW
    border-radius: 5px
    svg
      fill: $c_white
      width: 60%
      height: 60%

  &--phone
    color: $c_white
    text-decoration: none
    font-size: 20px
    border: 1px solid $c_white
    border-radius: 5px
    display: flex
    align-items: center
    padding: 0 $paddingBase 0 $paddingBase*0.25
    svg
      margin: 0 $paddingBase 0 $paddingBase*0.5

  &--email
    background: $vivaYellow
    svg
      fill: $c_black

  &--wa
    background: #333
    svg
      fill: $vivaGreenDark

@include second-breakpoint()
  body
    padding-bottom: 120px